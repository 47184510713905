<style scoped>
.detailCon {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  margin-top: 40px;
}
.detailCon.noCopy {
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
p {
  margin-bottom: 0;
}
.detailCon p {
  width: 100%;
  font-size: 2rem !important;
}
.back {
  position: fixed;
  top: 0;
  padding: 0 20px;
  width: 100%;
  height: 3rem;
  z-index: 999;

  line-height: 3rem;
  font-size: 1.2rem;
  box-shadow: 0px 3px 3px rgb(35 68 81 / 22%);
  display: flex;
}

.bgClass {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  pointer-events: none;
  z-index: -1;
}
</style>
<style>
.showHtml video {
  margin: 0 auto;
  width: 100% !important;
  height: auto !important;
}
.detailCon img {
  width: 100% !important;
}
</style>

<template>
  <div style="color: #fff">
    <div class="detailCon">
      <div>
        <div class="back">
          <div style="text-align: left">
            <span style="display: inline-block" @click="back">
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
            </span>
          </div>
          <div style="flex: 1; text-align: center">
            <!-- 奖励规则 -->

            <h3 style="font-size: 15px">{{ detailData.title }}</h3>
          </div>
        </div>
        <!-- <div style="text-align: left; padding: 20px 18px 0px">
          <h3 style="font-size: 20px">{{ detailData.title }}</h3>
        </div> -->
        <div style="display: flex">
          <div style="height: 50%; padding: 20px 18px 0; text-indent: 1em">
            <span>{{ detailData.desc }}</span>
          </div>
        </div>

        <div
          style="padding: 23px 18px; font-size: 16px !important"
          class="showHtml"
          v-html="htmlData"
        ></div>
      </div>
    </div>

    <!-- backgroundUrl: `https://mgt.gtrmt.cn/${detailData.bgUrl}`, -->
    <!-- :style="{
        color: red,
        backgroundUrl:
          'https://mgt.gtrmt.cn/static/gtttueditor/newresource/resources/coverVote/d282489637054fa3cb98dbddde8f4900.jpg',
      }" -->
    <!-- 'https://mgt.gtrmt.cn/static/gtttueditor/newresource/resources/coverVote/d282489637054fa3cb98dbddde8f4900.jpg', -->
    <!-- v-if="detailData.bgUrl" -->
    <div
      class="bgClass"
      :style="{
        color: 'red',
        background: detailData.bgUrl
          ? 'url(' + 'https://mgt.gtrmt.cn/' + detailData.bgUrl + ')'
          : 'linear-gradient(180deg, #673ab7, #5369e3)',
      }"
    ></div>
  </div>
</template>

<script>
/* eslint-disable  */
import debug from "debug";
import { wxConPost } from "../api/httpApi";
import wxLoginApi from "../api/wxLoginApi";
// import $ from "jquery";

const _d = debug("@pages:detailCon");
const _ = require("lodash");
// const cheerio = require("cheerio");

export default {
  name: "detailConPages",
  data() {
    return {
      canvsUrl: "", // 水印
      name: "", // 水印名字
      title: "",
      htmlData: "",
      detailData: {},
      isShowShare: false,
      ifView: false, // 是否显示评论

      showTop: false, // 是否位于顶部
      top: 0,
      duration: 1000,

      likeStatus: false,
      collectionStatus: false,
      collectionBumber: 0,
      viewCount: 0,
      // 评论回复数据
      replyList: {},
      // 是否显示弹出框
      isShowUp: false,

      id: "",
    };
  },
  methods: {
    /**
     * 为网页添加文字水印
     * @param {String} str 要添加的字符串
     */
    addWaterMarker(str) {
      var can = document.createElement("canvas");
      var body = document.body;
      body.appendChild(can);
      can.width = 100;
      can.height = 100;
      can.style.display = "none";
      var cans = can.getContext("2d");
      cans.rotate((-20 * Math.PI) / 180);
      cans.font = "16px Microsoft JhengHei";
      cans.fillStyle = "rgba(240, 240, 240, 1)";
      cans.textAlign = "left";
      cans.textBaseline = "Middle";
      cans.fillText(str, can.width / 4, can.height / 2);
      this.canvsUrl = "url(" + can.toDataURL("image/png") + ")";
    },

    closeView() {
      this.showEnlargeImg = false;
    },

    // 搜索
    toSearch() {
      this.$router.replace("search");
    },
    // 截取字符串
    getQueryString(name) {
      const url = window.location.href;
      const r = url.match(
        // eslint-disable-next-line
        new RegExp(`([\?|&])${name}=([^|&||#|\/]*)(&|#|\/|$)`)
      );
      if (r !== null) {
        const str = decodeURI(r[2]);
        const pos = str.indexOf("#/");
        if (pos >= 0) {
          return str.substr(0, pos);
        }
        debug("str====", str);
        return str;
      }
      return null;
    },
    // 获取内容详情
    async getViewData() {
      // 兼容 _cid 数据库类型存储 Double 和 Srting 的类型问题
      const query = {
        id: this.id,
      };
      try {
        const ret = await wxConPost(
          "/services/gttt-answer-exam/reward/rewardDetail",
          query
        );

        this.detailData = ret.ret;
        this.htmlData =
          this.detailData && this.detailData.content
            ? this.detailData.content
            : "";
      } catch (error) {}
    },

    // 处理Html
    fliterHtml(html) {
      var style_regx = /<style.*?>.*?<\/style>/g;
      var body_rgex = /<body.*?>(.*?(\n))*.*?<\/body>/;
      var cnt_regx = /([\}|\>][\.\+\>\,\w\ \*]+\{)/g;
      // 获得样式列表
      var styleArr = html.match(style_regx);
      if (_.isEmpty(styleArr)) {
        return html;
      } else {
        // 处理样式表
        styleArr.map(function (style, key) {
          let styleList = style.match(cnt_regx);
          styleList.forEach(function (v, k) {
            let cnt_replace = v;
            if (k === 0) {
              cnt_replace = ">" + ".showHtml " + v.substring(1);
            } else {
              cnt_replace = "}" + ".showHtml " + v.substring(1);
            }
            cnt_replace = cnt_replace.replace(/\,/, ",.showHtml ");
            styleArr[key] = styleArr[key].replace(v, cnt_replace);
          });
        });
        var body = html.match(body_rgex);
        debug("111111", body);
        if (!_.isEmpty(body)) {
          body = body[0].replace(/((\<body.*?\>)|(\<\/body\>))/g, "");
          debug("body--------", body);
        } else {
          body = "";
        }
        return html + styleArr.join("") + body;
      }
    },

    // 回退
    back() {
      this.$router.back(-1);
    },
  },

  components: {},
  async created() {
    //  初始化姓名
    wxLoginApi.onReady(() => {});

    const query = this.$route.query;
    if (query.id) {
      this.id = query.id;
      await this.getViewData();
    }
  },

  destroyed() {},
};
</script>
